Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.ACCOUNT_TYPE_EMAIL = "EmailAccount";
exports.ACCOUNT_TYPE_SOCIAL = "SocialAccount";
exports.ACCOUNT_TYPE_PHONE = "SmsAccount";

exports.contentTypeApiUpdateUser = "application/json";
exports.apiEndPointUpdateUser = "profile/profile";
exports.apiUpdateUserType = "PUT";

exports.urlGetValidations = "profile/validations";
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";

exports.contenttypeApiValidateMobileNo = "application/json";
exports.endPointApiValidateMobileNo = "profile/change_phone_validation";
exports.callTypeApiValidateMobileNo = "POST";

exports.endPointApiGetUserProfile = "profile/profile";
exports.contentTypeApiGetUserProfile = "application/json";
exports.methodTypeApiGetUserProfile = "GET";

// Customizable Area Start
exports.placeHolderEmail = "Email";
exports.labelHeader =
  "This is your profile, Here you can see and update your personal information.";
exports.labelFirstName = "First name";
exports.lastName = "Last name";
exports.labelArea = "Area";
exports.labelMobile = "Mobile";
exports.labelEmail = "Email";
exports.labelCurrentPassword = "Current password";
exports.labelNewPassword = "New Password";
exports.labelRePassword = "Re-Type Password";
exports.btnTextCancelPasswordChange = "Cancel";
exports.btnTextSaveChanges = "Save Changes";
exports.btnTextChangePassword = "Change Password";
exports.errorCountryCodeNotSelected = "Please select country code";
exports.errorMobileNoNotValid = "Phone number is not valid.";
exports.errorTitle = "Error";
exports.errorBothPasswordsNotSame = "Passwords must match.";
exports.errorCurrentNewPasswordMatch = "New password cannot match current password.";
exports.errorCurrentPasswordNotValid = "Current password not valid.";
exports.errorNewPasswordNotValid = "New password not valid.";
exports.errorReTypePasswordNotValid = "Re-type password not valid.";
exports.hintCountryCode = "Select Country";
exports.errorBlankField = "can't be blank";
exports.errorEmailNotValid = "Email not valid.";
exports.employeeName = "Employee Name";
exports.emailId = "Email ID";
exports.contactName = "Contact Name";
exports.contactNumber = "Phone Number";
exports.kycStatus = "KYC Check done";
exports.actionPerform = "Action";
exports.loadText = "Load more";
exports.userInformation = "Employees Information (10)";
exports.verifyText = "Create Employee";

exports.contentTypeApiAddDetail = "application/json";
exports.accountSubscriptionEndPoint = "account_block/show_company";
exports.accountSubscriptionEndPoint2 = "bx_block_custom_user_subs/subscriptions/account_subscription";
exports.accountSubscriptionEmployeeEndPoint = "account_block/show_employee";
exports.updateCompanyProfileEndPoint = "account_block/update_company";
exports.updateEmployeeProfileEndPoint = "account_block/update_employee";

exports.hangePasswordCApiEndPoint= "bx_block_profile/passwords/employee_password";
exports.changePasswordEndPoint = "bx_block_profile/passwords/company_password";

exports.taxRegex =  /^[a-zA-Z0-9]{8,}$/;
exports.taxIdentificationNumberError = "Please enter valied tax number";
exports.websiteLinkError = "Please enter valid website link";
exports.webSiteRegex = /^(https?:\/\/)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(:\d+)?(\/.*)?$/;
exports.companySignupEndPoint = "account_block/create_company";
exports.phoneNumberText = "Phone Number";

exports.zipCodeText = "Zip Code*";
exports.zipCodeRegEx = /^\d{6}$/;
exports.zipCodeError = "Please enter valied zip code";

exports.companyAddressError = "You cannot exceed 100 words and this field cannot be empty";
exports.cityErrorMessage = "You are not able to write more than 52 words or is should not be empty";
exports.companyNameError = "You are not able to write more than 52 words or is should not be empty";
exports.companyNameExit = "Company Name already exists.";
exports.uploadText = "Upload Picture in JPEG, PNG up to 10MB";
exports.uploadTextErr = "Uploaded Picture is not in JPEG, PNG and up to 10MB";
exports.uploadFileTypes = ['image/jpeg', 'image/png'];
exports.CreditListEndpoints = "bx_block_custom_user_subs/subscriptions";
exports.planListEndpoints = "bx_block_custom_user_subs/subscriptions/subscription_plan";
// Customizable Area End

