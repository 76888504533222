import React from "react";
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Button,
  TableContainer,
  IconButton,
} from "@material-ui/core";
import { styled } from "@material-ui/styles";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CancelIcon from "@material-ui/icons/Cancel";
import BuyCreditController, {
  Props,
} from "../../../blocks/user-profile-basic/src/BuyCreditController.web";
import Headers from "../../../components/src/Headers.web";
import { ArrowBack } from "@mui/icons-material";
import SuccessDailogBoxProfile from '../../../components/src/SuccessDailogBoxProfile.web';
import FailDailogBox from '../../../components/src/FailDailogBox.web';
import PaymentDetailsBox from "../../../components/src/PaymentDetailsBox.web";

export default class BuyCredit extends BuyCreditController {
  constructor(props: Props) {
    super(props);
  }

  renderBuyCredit() {
     return (
      <MainContent>
        <PageTop>
          <Typography variant="h5" className="headerText">
            Buy Credits
          </Typography>
        </PageTop>
        <StyledTableContainer>
          <StyledTable>
            <TableHead>
              <StyledTableRow>
                <TableCell>
                  <UpgradeInfo>
                    <Typography variant="subtitle1">
                      Buy credits for KYC
                    </Typography>
                    <Typography variant="body2">
                      Simple, transparent pricing that grows with you. Try any
                      plan free for 30 days.
                    </Typography>
                  </UpgradeInfo>
                </TableCell>
                {this.state.creditPlanlist.map((plan, index) => (
                  <TableCell
                    key={index}
                    className="planTableCell"   
                    style={{ backgroundColor: this.state.checkCurrentPlan==plan.attributes.name ? "#111315" : "" }}                
                  >
                     <Typography variant="h6">{plan.attributes.name}</Typography>
                     <Typography variant="h5">${parseInt(plan.attributes.price)}/mth</Typography>
                  </TableCell>
                ))}
              </StyledTableRow>
            </TableHead>
            <TableBody>
              {this.state.features.map((feature, index) => (
                <StyledTableRow key={index}>
                  <TableCell className="featureTableCell">
                    {feature} 
                  </TableCell>
                  {this.state.creditPlanlist.map((plan, planIndex) => (
                    <TableCell
                      key={planIndex}
                      align="center" 
                      style={{ backgroundColor: this.state.checkCurrentPlan==plan.attributes.name ? "#111315" : "" }}                   
                    >          
                  {
                   plan.attributes.subscriptions_plan_benefit_id.includes(feature)? <CheckCircleIcon style={{ color: "#00FF00" }} />: <CancelIcon style={{ color: "#515151" }} />
                  }
                    </TableCell>
                  ))}
                </StyledTableRow>
              ))}
              <StyledTableRow>
                <TableCell />

                {this.state.creditPlanlist.map((plan, index) => (
                  <TableCell
                    key={index}
                    align="center"    
                    style={{ backgroundColor: this.state.checkCurrentPlan==plan.attributes.name ? "#111315" : "" }}             
                    onClick={this.handlePaymentDialogBox}
                    data-testId="BuyCreditToggleTest"
                  >
                    <StyledButton
                      variant="contained"
                      color={this.state.checkCurrentPlan==plan.attributes.name ? "secondary" : "primary"}
                    >
                      {this.state.checkCurrentPlan==plan.attributes.name ? "Current Plan" : "Purchase"}
                    </StyledButton>
                  </TableCell>
                ))}
              </StyledTableRow>
            </TableBody>
          </StyledTable>
        </StyledTableContainer>
        <SuccessDailogBoxProfile
         data-testId="confirmDialog_test"
         open={this.state.successDialogOpen}
         onClose={this.handleCancel}   
        />
        <FailDailogBox
         data-testId="confirmDialog_test2"
         open={this.state.failDialogOpen}
         onClose={this.handleCancel}
         handleSuccessDialogBox={this.handleSuccessDialogBox}
        />
        <PaymentDetailsBox
         data-testId="confirmDialog_test3"
         open={this.state.isDialogOpen}
         onClose={this.handleCancel}         
         handleFailDialogBox={this.handleFailDialogBox}
        />
      </MainContent>
    );
  }

  render() {
    return (
      <Headers
        sliderButton={this.state.sideBarClose}
        logOutToogle={this.logOutBtn}
        data-testId="logoutTestId"
        onToggleClick={this.handleSliderButton}
        goToScreen={this.navigateToScreen}
        isRouteData="BuyCredit"
      >
        {this.renderBuyCredit()}
      </Headers>
    );
  }
}

const MainContent = styled(Box)({
  padding: "20px",
  backgroundColor: "rgba(26, 29, 31, 1)",
  fontFamily: "Lato",
  height: "100vh",
  overflow: "auto"
});

const PageTop = styled(Box)({
  marginBottom: "20px",
  display: "flex",
  alignItems: "center",
  "& .headerText": {
    fontFamily: "Lato",
    color: "rgba(252, 252, 252, 1)",
    fontSize: "40px",
    fontWeight: 400,
    lineHeight: "48px",
    initialLetter: "-2%"
  },
});

const UpgradeInfo = styled(Box)({
  width: "304px",
  "& .MuiTypography-subtitle1": {
    fontSize: "22px",
    marginBottom: "5px",
    color: "rgba(255, 255, 255, 1)",
    fontFamily: "Lato",
    fontWeight: 400,
    lineHeight: "26.4px",
  },
  "& .MuiTypography-body2": {
    fontSize: "14px",
    marginBottom: "5px",
    color: "rgba(211, 211, 211, 1)",
    fontFamily: "Lato",
    fontWeight: 400,
    lineHeight: "16.8px"
  },
  "&::after": {
    content: '""',
    display: "block",
    width: "30px",
    borderBottom: "2px solid rgba(124, 124, 124, 1)", 
    marginTop: "13px",
  },
});

const StyledTableContainer = styled(TableContainer)({
  marginTop: "20px",
  backgroundColor: "#18191b",
  height: "654px",
  margin:"auto",
  gap: "40px",
});

const StyledTableRow = styled(TableRow)({
  "&:not(:last-child)": {
    "& .MuiTableCell-root": {
      borderBottom: "1px solid rgba(124, 124, 124, 0.2)",
      width: "50%",
    },
  },
});

const StyledTable = styled(Table)({
  "& .MuiTableCell-root": {
    borderBottom: "none",
    color: "white",
    padding: "16px 24px",
  },
  "& .MuiTableCell-head": {
    fontWeight: "bold",
    width: "200px",
  },
  "& .planTableCell": {
    textAlign: "start",
    "& .MuiTypography-h6": {
      fontFamily: "Lato",
      fontWeight: 400,
      fontSize: "17px",
      lineHeight: "20px",
      color: "rgba(167, 167, 167, 1)",
      marginBottom: "9px",
      width: "100px",
      margin: "auto",
    },
    "& .MuiTypography-h5": {
      fontFamily: "Lato",
      fontWeight: 400,
      fontSize: "18px",
      lineHeight: "29px",
      color: "rgba(255, 255, 255, 1)",   
      borderBottom: "2px solid rgba(124, 124, 124, 0.2)",
      paddingBottom: "20px",
      width: "100px",
      margin: "auto",
    },
  },
  "& .featureTableCell": {
    color: "rgba(255, 255, 255, 1)",
    fontSize: "16px",
    fontWeight: 400,
    fontFamily: "Lato",
    lineHeight: "24px",
  },
});

const StyledButton = styled(Button)({
  height: "47px",
  width: "162px",
  padding: "8px 16px",
  textTransform: "none",
  fontSize: "14px",
  fontWeight: 600,
  fontFamily: "Lato",
  "&.MuiButton-containedPrimary": {
    backgroundColor: "rgba(0, 255, 0, 1)",
    color: "rgba(36, 36, 36, 1)",
  },
  "&.MuiButton-containedSecondary": {
    backgroundColor: "#111315",
    color: "white",
    border: "2px solid rgba(0, 255, 0, 1)",
  },
});
