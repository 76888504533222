import React from "react";
// Customizable Area Start
import UserProfileBasicController, { Props } from "./UserProfileBasicController.web";
import { Box,Grid,Avatar, Typography, Button } from '@mui/material';
import { styled } from '@mui/system';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import Headers from '../../../components/src/Headers.web';
import EmployeHeader from '../../../components/src/EmployeHeader.web'
import { UpgradeIcon,DeleteBox} from "./assets";
const profileImg = require("../assets/image.png");
import SuccessDailogBoxProfile from '../../../components/src/SuccessDailogBoxProfile.web';
import FailDailogBox from '../../../components/src/FailDailogBox.web';
import PaymentDetailsBox from "../../../components/src/PaymentDetailsBox.web";
// Customizable Area End

export default class UserProfileBasic extends UserProfileBasicController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
   // Customizable Area Start
   
  
   renderUserProfileImage = () => {
    return (
        <>
            <Box
                className="userImage" data-testId="avatarBoxTestId">
                {
                (this.state.role === "company" && this.state.userProfilePerticulerCompany.image) ?
                    <img src={this.state.userProfilePerticulerCompany.image} alt="" className="userProfileImage" /> 
                    :
                    (this.state.role === "employee" && this.state.userProfilePerticulerEmployee.image) ?
                      <img src={this.state.userProfilePerticulerEmployee.image} alt="" className="userProfileImage" />
                    :
                    <img src={profileImg} alt="default profile" className="userProfileImage" />
                }
            </Box>
        </>
    );
};


   renderUsersProfile = () => {
    return(
      <UserProfileBasicStyle>
      <div className="root">      
        <Grid spacing={3} style={{padding:"20px 10px"}}>
          <Grid item xs={11} className="profileHeader">          
            <Typography className="profileName">Profile</Typography> 
            <div>
            <Button variant="outlined" className="buttonChangePass" onClick={() => this.goToScreen("ChangePassword")} data-testId="ChangePassButton">
              Change Password
            </Button>
            <Button variant="contained" className="button" style={{ marginLeft: "8px" }} onClick={() => {
                this.state.role === "company"
                  ? this.goToUserProfileEditScreen()
                  : this.goToUserProfileEditScreenEmployee();
              }} data-testId="btnTest2" >
              Edit Profile
            </Button>
            </div>
          </Grid>
          <Grid item xs={11} className="statsContainer">
            <div>
            <div>
            {this.renderUserProfileImage()}
            </div>
            { this.state.role === "company" ?
            <Typography className="profileName2">{this.state.userProfilePerticulerCompany.personName}</Typography> 
            :
            <Typography className="profileName2">{this.state.userProfilePerticulerEmployee.employeeName}</Typography> 
            }
            </div>                   
            <div style={{display:"flex",justifyContent:"space-between",flexDirection:"column",gap:"20px"}} className="bigBox">
              { this.state.role === "company" &&
               <div style={{width:"100%",backgroundColor:"#313131",display:"flex"}}> 
              <div style={{padding:'16px',backgroundColor:"#313131",display:"flex",width:"25%",justifyContent:"space-between"}}>
              <div style={{display:"flex",flexDirection:"column",justifyContent:"space-between",gap:"10px"}}>
              <Typography  className="Headingtop">Total Credits</Typography>
              <Typography className="ParaBottom">{this.state.userProfilePerticulerCompany.totalCredits}</Typography>  
              </div>
              <div style={{height:"90%",backgroundColor:"white", width:"1px",margin:"10px 0",justifyContent:"space-between"}}></div>            
              </div>              
              <div style={{padding:'16px',backgroundColor:"#313131",display:"flex",width:"25%",justifyContent:"space-between"}}>
                <div style={{display:"flex",flexDirection:"column",justifyContent:"space-between",gap:"10px"}}>
                <Typography className="Headingtop" >Available Credits</Typography>
                <Typography variant="h4">{this.state.userProfilePerticulerCompany.avaliableCredits}</Typography>
                </div>
                <div style={{height:"90%",backgroundColor:"white", width:"1px",margin:"10px 0"}}></div>
              </div>
              <div style={{padding:'16px',backgroundColor:"#313131",display:"flex",width:"25%",justifyContent:"space-between"}}>
              <div style={{display:"flex",flexDirection:"column",justifyContent:"space-between",gap:"10px"}}>
              <Typography className="Headingtop"  >Buy Credits</Typography>
              <AddCircleOutlineIcon style={{ fontSize: 40,cursor:"pointer" }} onClick={() => this.goToScreen("BuyCredit")} data-testId="RenewSubscriptionTest" />
              </div>
              <div style={{height:"90%",backgroundColor:"white", width:"1px",margin:"10px 0"}}></div>
              </div>
              <div style={{padding:'16px',backgroundColor:"#313131",width:"25%",display:"flex",flexDirection:"column",justifyContent:"space-between",gap:"10px"}}>
              <Typography className="Headingtop"  >Renew Monthly <Typography className="Headingtop">Subscription</Typography></Typography>
              <img alt="logo" src={UpgradeIcon} style={{ width:"40px",cursor:"pointer" }} onClick={() => this.goToScreen("RenewSubscription")} data-testId="RenewSubscriptionTest"/>         
              </div>
              </div> 
              }
              <Box className="companyInfoBox" style={{backgroundColor:"#313131"}}>
              <div style={{display:"flex",alignItems:"center",marginBottom:"8px"}}>
              <img alt="logo" src={DeleteBox} style={{ width:"20px",marginRight:"10px"}} /> 
              <Typography className="companyInfoTitle" style={{marginTop:"4px",whiteSpace:"nowrap"}}>
               { this.state.role === "company" ? "Company Information" : "Profile Information"  }
              </Typography>
              </div>
              <Typography className="companyInfoTitle2">{this.state.role === "company" ? this.state.userProfilePerticulerCompany.companyName : this.state.userProfilePerticulerEmployee.companyName }</Typography>
              <Typography className="companyInfoTitle3">Website: {this.state.role === "company" ? this.state.userProfilePerticulerCompany.websiteLink : this.state.userProfilePerticulerEmployee.websiteLink }</Typography>
              <Typography className="companyInfoTitle3" style={{marginBottom:"8px"}}>Company ID: {this.state.role === "company" ?  this.state.userProfilePerticulerCompany.companyUniqueId : this.state.userProfilePerticulerEmployee.companyUniqueId  }</Typography>
              { this.state.role === "company" ? 
              <>
              <Typography className="companyInfoTitle3" style={{marginBottom:"8px"}} >Tax identification number: {this.state.userProfilePerticulerCompany.taxIdentificationNumber}</Typography>
              <Typography className="companyInfoTitle3">
                Address: {this.state.userProfilePerticulerCompany.companyAddress}
              </Typography>
              </>
               :
               <Typography className="companyInfoTitle3">Phone number: {this.state.userProfilePerticulerEmployee.fullPhoneNumber}</Typography>
               }
            </Box>
            </div>           
          </Grid>      
          <Grid item xs={11} style={{display:"flex",justifyContent:"space-between"}}>     
          </Grid>
        </Grid>      
      </div>
      <SuccessDailogBoxProfile
         data-testId="confirmDialog_test"
         open={this.state.successDialogOpen}
         onClose={this.handleCancel}   
        />
        <FailDailogBox
         data-testId="confirmDialog_test2"
         open={this.state.failDialogOpen}
         onClose={this.handleCancel}
         handleSuccessDialogBox={this.handleSuccessDialogBox}
        />
        <PaymentDetailsBox
         data-testId="confirmDialog_test3"
         open={this.state.isDialogOpen}
         onClose={this.handleCancel}         
         handleFailDialogBox={this.handleFailDialogBox}
        />
     </UserProfileBasicStyle>
    )
  }
  // Customizable Area End
  render() {
    // Customizable Area Start
    // Customizable Area End
    return (
      // Customizable Area Start    
      <>
      {
        this.state.role === "company" ? 
        <Headers isRouteData={"UserProfileBasicWeb"} sliderButton={this.state.sideBarClose}
        logOutToogle={this.logOutBtn} 
        data-testId="logoutTestId"
        onToggleClick={this.handleSliderButton}
        goToScreen = {this.goToScreen}
        >
          {this.renderUsersProfile()}          
      </Headers> 
      :
      <EmployeHeader isRouteData={"UserProfileBasicWeb"} 
      sliderButton={this.state.sideBarClose}
        logOutToogle={this.logOutBtn} 
        data-testId="logoutTestId"
        onToggleClick={this.handleSliderButton}
        goToScreen={this.goToScreen}
      >
         {this.renderUsersProfile()}
      </EmployeHeader>
      }
    </>
      // Customizable Area End
    );
  }
}
// Customizable Area Start
const UserProfileBasicStyle = styled(Box)({
  "& .root": {
    backgroundColor: "#242424",
    color: "#fff",
    minHeight: "100vh",
    padding: "32px",  
    overFlowX:"hidden",  
    "@media (max-width: 1200px)": {
      padding: "32px 15px",
    },
  },
  "& .profileHeader": {
    display: "flex",  
    marginBottom: "16px",
    justifyContent:"space-between"
  },
  "& .avatar": {
    width: "160px",
    height: "160px",    
    border: "2px solid #00ff00",
  },
  "& .profileName": {
    fontFamily: 'Lato',
    fontSize: '40px',
    fontWeight: 600,
    lineHeight: '48px',
    letterSpacing: '-0.02em',
    textAlign: 'left',
    color:"#FCFCFC"
  },
  "& .profileName2": {
    fontFamily: 'Lato',
    fontSize: '32px',
    fontWeight: 700,
    color:"#D3D3D3",
    whiteSpace:"nowrap"
  },
  "& .buttonChangePass": {
    marginLeft: "16px",
    color: "#00FF00",
    backgroundColor: "#242424",
    border:"1px solid #FFFFFF",
    fontFamily: 'Lato',
    fontSize: '16px',
    fontWeight: 600,   
    textAlign: 'center',
    padding:"10px",  
    width:"162px",
    whiteSpace:'nowrap',
    textTransform: 'none',
    "&:hover": {
      backgroundColor: "#00e000",
      color: "#000",
    },
  },
  "& .button": {
    marginLeft: "16px",
    color: "#242424",
    fontFamily: 'Lato',
    fontSize: '16px',
    fontWeight: 600,   
    textAlign: 'center',
    padding:"10px",  
    width:"162px",
    backgroundColor: "#00ff00", 
    textTransform: 'none',
    "&:hover": {
      backgroundColor: "#00e000",
    },
  },
  "& .statsContainer": {
    display:"flex",
    justifyContent:"space-between",
    marginBottom: "16px",
  },
  "& .statBox": {
    textAlign: "center",
    padding: "16px",
    color: "#000",
  },
  "& .companyInfoBox": {
    backgroundColor: "#333",
    padding: "16px",
  },
  "& .companyInfoTitle": {
    fontFamily: 'Lato',
    fontSize: '20px',
    fontWeight: 600,   
    textTransform: 'none',
    color:"#F8FAFC"
  },
  "& .companyInfoTitle2": {
    fontFamily: 'Lato',
    fontSize: '32px',
    fontWeight: 700,   
    textTransform: 'none',
    color:"#D3D3D3"
  },
  "& .companyInfoTitle3": {
    fontFamily: 'Lato',
    fontSize: '20px',
    fontWeight: 600,  
    textTransform: 'none',
    color:'#A7A7A7'
  },
  "& .tabsStyle .MuiTab-textColorPrimary.Mui-selected": {
    backgroundColor: "#00FF00",
  },
  "& .Headingtop": {
    fontFamily: 'Lato',
    fontSize: '22px',
    fontWeight: 600,  
    textTransform: 'none' ,
    "@media (max-width: 1300px)": {
      fontSize: '18px',
    },
  },
  "& .ParaBottom": {
    fontFamily: 'Lato',
    fontSize: '32px',
    fontWeight: 700,   
    textTransform: 'none' 
  },  
  "& .userImage": {
    width: "150px",
    height: "150px",
    border: "3px solid #00FF00",
    borderRadius: "50%",
    marginTop: "25px",
    marginBottom: "10px",
},
"& .userProfileImage": {
    width: "100%",
    height: "100%",
    borderRadius: "50%",
},
  "& .bigBox": {
    width: '80%',    
    marginLeft:"10px",
    "@media (max-width: 1400px)": {
      width: '80%', 
    },
    "@media (max-width: 1200px)": {
      width: '75%', 
    },
  }
});
// Customizable Area End
