import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { ICEmployeeFileds } from "./types";
import React, { ChangeEvent } from 'react';
import { getStorageData } from "../../../framework/src/Utilities"
import { toast } from "react-toastify";
export const configJSON = require("./config");
export interface CountryCodeObj {
  name: string;
  dialCode: string;
  countryCode: string;
  format: string;
}
export interface SearchData {
  id: number;
  userPic:string;
  name: string;
  kycCheck: string;
  passportNo:number;
  documents:Array<string>;
  documentName:Array<string>;
  status:string;

}
export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export interface S {
  id: string;
  // Customizable Area Start
  sideBarClose:boolean;
  userState: ICEmployeeFileds;
  nameError:string | null;
  isButtonDisabled:boolean;
  emailError:string | null;
  designation: string;
  phoneNumber: string;
  employeeEmail: string;
  employeeName: string;
  deginationError:string | null;
  empNameError:string | null;
  token:string;
  PhoneNumberValidationCheck:number;
  phoneCountryCode: CountryCodeObj;
  phoneNumberStatus: string;
  anchorEl: HTMLButtonElement | null;
  isDialogOpen:boolean;
  successDialogOpen:boolean;
  employeeId: number;
  eeID:string;
  Editdesignation: string;
  EditphoneNumber: string;
  EditemployeeEmail: string;
  PassportStatus:string;
  PassportNo:string;
  EditemployeeName: string;
  tablesData:SearchData[];
  rejectedText:boolean;
  isEditButtonDisabled:boolean;
  // Customizable Area End
}

export interface SS {
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export default class InviteEmployeeController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  initialState: ICEmployeeFileds = {
    designation: "",
    phoneNumber: "",
    employeeEmail: "",
    employeeName: ""
  };
 inviteEmployeeApiCallId : string;
 showEmployeeApiCallId : string;
 updateEditEmployeeAPIId : string;
  // Customizable Area End

  constructor(props: Props) {
    super(props);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      id: "0",
      designation: "",
      phoneNumber: "",
      employeeEmail: "",
      employeeName: "",
      sideBarClose :false,
      userState: this.initialState,
      nameError:"",
      isButtonDisabled:true,
      emailError:"",
      deginationError:"",
      empNameError:"",
      token:"",
      PhoneNumberValidationCheck:100,
      phoneCountryCode: {} as CountryCodeObj,
      phoneNumberStatus:"",
      anchorEl:  null,
      isDialogOpen:false,
      successDialogOpen:false,
      employeeId:0,
      Editdesignation:"",
      EditphoneNumber:"",
      EditemployeeEmail:"",
      PassportStatus:"",
      PassportNo:"",
      EditemployeeName:"",
      eeID:"",
      tablesData: [
        {
          id: 1,
          userPic:
            "https://cdn.pixabay.com/photo/2024/02/24/22/38/ai-generated-8594850_1280.png",
          name: "David Smith",
          kycCheck:"20/03/2023",
          passportNo: 2343434343,
          documents: ["doc1", "doc2", "doc3","doc4"],
          documentName:["passport.pdf", "document.pdf","passport.pdf", "document1.pdf"],
          status: "approved",
        },
        {
          id: 2,
          userPic:
            "https://cdn.pixabay.com/photo/2024/02/24/22/38/ai-generated-8594850_1280.png",
          name: "Aisyah Clara",
          kycCheck:"20/03/2023",
          passportNo: 2343434343,
          documents: ["doc1", "doc2", "doc3","doc4"],
          documentName:["passport.pdf", "document.pdf","passport.pdf", "document1.pdf"],
          status: "pending",
        },
        {
          id: 3,
          userPic:
            "https://cdn.pixabay.com/photo/2024/02/24/22/38/ai-generated-8594850_1280.png",
          name: "Anastasia De",
          kycCheck:"20/03/2023",
          passportNo: 2343434343,
          documents: ["doc1", "doc2", "doc3","doc4"],
          documentName:["passport.pdf", "document.pdf","passport.pdf", "document1.pdf"],
          status: "approved",
        },
        {
          id: 4,
          userPic:
            "https://cdn.pixabay.com/photo/2024/02/24/22/38/ai-generated-8594850_1280.png",
          name: "Shirely Wong",
          kycCheck:"20/03/2023",
          passportNo: 2343434343,
          documents: ["doc1", "doc2", "doc3","doc4"],
          documentName:["passport.pdf", "document.pdf","passport.pdf", "document1.pdf"],
          status: "rejected",
        },
      ],
      rejectedText:true,
      isEditButtonDisabled:true
      // Customizable Area End
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    this.inviteEmployeeApiCallId = "",
    this.showEmployeeApiCallId = "",
    this.updateEditEmployeeAPIId = ""
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    let eeid = await getStorageData("eeid");
    const tokenId = await getStorageData("token");
    this.setState({employeeId : Number(eeid),token:tokenId, eeID:eeid})
    if(eeid){
      this.showEmployee();
    }
  }

  async componentDidUpdate(prevProps: Props, prevState:  {successDialogOpen: boolean}) {
    if (prevState.successDialogOpen && !this.state.successDialogOpen) {
      this.goToPeopleManagementScreen();
    }
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
    if (apiRequestCallId === this.inviteEmployeeApiCallId) {
      this.handleApiResponse(responseJson)
    }
    if(apiRequestCallId === this.showEmployeeApiCallId){
        this.handleEditData(responseJson)
      }
    if(apiRequestCallId === this.updateEditEmployeeAPIId){
      this.handleUpdatedData(responseJson)
      }
    }
    // Customizable Area End
  }
 //@ts-ignore
  handleEditData = (responseJson) => {
    const rsData = responseJson.data.attributes
  if(rsData.company_unique_id){
    this.setState({
      Editdesignation:rsData.designation,
      EditphoneNumber:rsData.full_phone_number,
      EditemployeeName:rsData.employee_name,
      EditemployeeEmail:rsData.email,
      PassportNo:rsData.passport_number,
      PassportStatus:rsData.status
    })
  }
  }

 //@ts-ignore
  handleUpdatedData = (responseJson) => {
    toast.success(responseJson.meta.message)
    this.setState({
      Editdesignation:"",
      EditphoneNumber:"",
      EditemployeeName:"",
      EditemployeeEmail:""
    })
    this.goToPeopleManagementScreen()
  }

  handleApiResponse = (response:{
    errors?: { token: string }[];
    message?: string;
  })=> {
    if (response.errors && response.errors.length > 0) {
      const error = response.errors[0].token;
      if (error === "Token has Expired") {
        toast.error("Token has expired. Please login again.");
      }
    } else if (response.message) {
      if (response.message === "Email already exists") {
        this.setState({  isDialogOpen: false ,anchorEl: null})
        toast.error(response.message);
      } else if (response.message === "Invite has been Successfully Sent") {
        this.setState({  isDialogOpen: false ,anchorEl: null,successDialogOpen:true, isButtonDisabled:true, designation: "",phoneNumber: "",employeeEmail: "",employeeName: "",});
      }
    }
  }

  handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  handleNeedClarification = () => {
    this.setState({ rejectedText: !this.state.rejectedText, anchorEl: null });
  };

  dailcodeData = async (value: string, data: CountryCodeObj, event: React.ChangeEvent<HTMLInputElement>, formattedValue: string) => {
    let dailCodeLenght= await data?.dialCode?.length;
    let numberData = await data?.format?.split('.').length - 1;
    let name= await data?.name; 
    this.setState({
      phoneNumber:value,PhoneNumberValidationCheck:numberData
    }, () => {
     this.validateForm() 
      if (this.state.phoneNumber.length !== this.state.PhoneNumberValidationCheck) {
        this.setState({ phoneNumberStatus: `Please write ${numberData-dailCodeLenght} digits instead of ${this.state.phoneNumber.length-dailCodeLenght}`});     
      } else {
        this.setState({ phoneNumberStatus: "" });
      }
    });
  }; 

  handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
    const { name, value } = event.target;
  
    const handleAlphaValidation = (
      fieldName: string,
       // @ts-ignore
      errorMessageKey: keyof State
    ): boolean => {
      const containsNonAlpha = /[^A-Za-z\s]/.test(value);
      const exceedsMaxLength = value.length > 52;
      if (containsNonAlpha) {
        this.setState({
          [errorMessageKey]:  "Please enter characters only",
          isButtonDisabled: true,
           // @ts-ignore
        } as Pick<State, keyof State>);
        return false;
      } else if (exceedsMaxLength) {
        this.setState({
          isButtonDisabled: false,
           // @ts-ignore
        } as Pick<State, keyof State>,this.validateForm);
        return false;
      }else {
         // @ts-ignore
        this.setState({ [errorMessageKey]: null } as Pick<State, keyof State>);
        return true;
      }
    };
  
    if (name === "employeeName") {
      if (!handleAlphaValidation('employeeName', 'empNameError')) return;
    }
  
    if (name === 'employeeEmail') {
      const emailPattern = configJSON.emailRegex;
      const isEmailValid = emailPattern.test(value);
      if (!isEmailValid) {
        this.setState({ emailError: "Enter a valid email address." });
        this.setState({ isButtonDisabled : true});
        console.log("log111",Boolean(this.state.emailError))
      } else {
        this.setState({ emailError: null });
      }
    }
  
    // @ts-ignore
    this.setState({ [name]: value } as Pick<State, keyof State>, this.validateForm);
  };

  validateForm = (): void => {
    const { designation,phoneNumber,employeeEmail,employeeName,PhoneNumberValidationCheck} = this.state;
    const emailPattern = configJSON.emailRegex;
    const isEmailValid = emailPattern.test(employeeEmail);
    const phoneNumberCheck = phoneNumber.length === PhoneNumberValidationCheck 
    const isButtonDisabled = !designation || !phoneNumberCheck || !isEmailValid || !employeeName;
    this.setState({ isButtonDisabled });
  };

  handleInvite = () => { 
    let data = {
      invite: 
      {email: this.state.employeeEmail.trim(),
      full_phone_number: this.state.phoneNumber,
      employee_name:this.state.employeeName.trim(),
      // company_name:this.state.companyName.trim(),
      designation: this.state.designation.trim()
      }
    };

    const header = {
      "Content-Type": configJSON.apiContentType,
      token: this.state.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.inviteEmployeeApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.sendInviteEmployeeApiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(data)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;  
  };

  showEmployee = () => {
    const header = {
      token:this.state.token
    };
    let id = this.state.employeeId
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.showEmployeeApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.showEmployeeApiEndPoint}?id=${id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  EditdailcodeData = async (value: string, data: CountryCodeObj, event: React.ChangeEvent<HTMLInputElement>, formattedValue: string) => {
    let editDailCodeLenght= await data?.dialCode?.length;
    let editNumberData = await data?.format?.split('.').length - 1;
    let name= await data?.name; 
    this.setState({
      EditphoneNumber:value,PhoneNumberValidationCheck:editNumberData
    }, () => {
     this.EditvalidateForm() 
      if (this.state.EditphoneNumber.length !== this.state.PhoneNumberValidationCheck) {
        this.setState({ phoneNumberStatus: `Please write ${editNumberData-editDailCodeLenght} digits`});     
      } else {
        this.setState({ phoneNumberStatus: "" });
      }
    });
  }; 

  editHandleChange = (event: ChangeEvent<HTMLInputElement>): void => {
    const { name, value } = event.target;

    const handleBetaValidation = (
      fieldName: string,
       // @ts-ignore
      errorMessageKeyEdit: keyof State
    ): boolean => {
      const containsNonBeta = /[^A-Za-z\s]/.test(value);
      const exceedsEditMaxLength = value.length > 52;
      if (containsNonBeta || exceedsEditMaxLength) {
        this.setState({
          [errorMessageKeyEdit]: containsNonBeta
            ? "Name must only contain letters and spaces."
            : "Name must be 52 characters or less.",
            isEditButtonDisabled: true,
           // @ts-ignore
        } as Pick<State, keyof State>);
        return false;
      } else {
         // @ts-ignore
        this.setState({ [errorMessageKeyEdit]: null } as Pick<State, keyof State>);
        return true;
      }
    };
  
    if (name === "Editdesignation") {
      if (!handleBetaValidation('Editdesignation', 'deginationError')) return;
    }
  
    if (name === "EditemployeeName") {
      if (!handleBetaValidation('EditemployeeName', 'empNameError')) return;
    }
  
    if (name === 'EditemployeeEmail') {
      const editEmailPattern = configJSON.emailRegex;
      const isEditEmailValid = editEmailPattern.test(value);
      if (!isEditEmailValid) {
        this.setState({ emailError: "Enter a valid email address." });
        this.setState({ isEditButtonDisabled : true});
      } else {
        this.setState({ emailError: null });
      }
    }
  
    // @ts-ignore
    this.setState({ [name]: value } as Pick<State, keyof State>, this.EditvalidateForm);
  };

  EditvalidateForm = (): void => {
    const { Editdesignation,EditphoneNumber,EditemployeeEmail,EditemployeeName,PhoneNumberValidationCheck} = this.state;
    const editEmailPattern = configJSON.emailRegex;
    const isEditEmailValid = editEmailPattern.test(EditemployeeEmail);
    const phoneNumberCheck = EditphoneNumber.length === PhoneNumberValidationCheck 
    const isEditButtonDisabled =  !Editdesignation || !phoneNumberCheck || !isEditEmailValid || !EditemployeeName;
    this.setState({ isEditButtonDisabled });
  };

  updateEmployee = ()=>{
    const formData = new FormData(); 
     formData.append("data[id]", this.state.eeID);
     formData.append("data[full_phone_number]", this.state.EditphoneNumber);
     formData.append("data[employee_name]", this.state.EditemployeeName);
     formData.append("data[designation]", this.state.Editdesignation);
     formData.append("data[email]", this.state.EditemployeeEmail);
 
    const header = {
      'token': this.state.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.updateEditEmployeeAPIId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.updateCompanyEmployeeEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.putApiMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleSliderButton = () =>{
    this.setState({sideBarClose:!this.state.sideBarClose});
  }

  logOutBtn = async () => {
    this.goToLoginScreen();
  }

  goToLoginScreen = () => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "LandingPage");
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  };

  goToPeopleManagementScreen = () => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "PeopleManagement");
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  };

  goToScreen = (screenName:string) => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
        message.addData(getName(MessageEnum.NavigationTargetMessage), screenName);
        message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        message.addData(getName(MessageEnum.NavigationScreenNameMessage), screenName);
        this.send(message)
  };
  handleCancel = () => {
    this.setState({ isDialogOpen: false ,anchorEl: null,successDialogOpen:false});
  };

  handleConfirm = () => {
    this.handleInvite()
  };

  handleDialogBox = () =>{
    this.setState({ isDialogOpen: true });
  }

  // Customizable Area End
}
