import React from "react";
// Customizable Area Start
import {
    Box,
    Typography,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TableContainer,
    Button,
    Input,
    IconButton,
    Menu,
    MenuItem,
} from "@material-ui/core";
import { styled } from "@material-ui/styles";
import MoreVertIcon from '@material-ui/icons/MoreVert';
import SearchIcon from '@material-ui/icons/Search';
import CustomFormController, {
    Props,
    configJSON,

} from "./CustomFormController.web";
import Headers from '../../../components/src/Headers.web';
import ConfirmDialog from '../../../components/src/CustomDialogBox.web';
import SuccessDialog from '../../../components/src/SuccessDailogox.web';
// Customizable Area End

export default class PeopleManagement extends CustomFormController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <Headers sliderButton={this.state.sideBarClose}
      logOutToogle={this.logOutBtn} 
      data-testId="logoutTestId"
      onToggleClick={this.handleSliderButton}
      goToScreen = {this.goToScreen}
      isRouteData="PeopleManagement"
      >
            <MainContent>
                <PageTop className="pageMain">
                    <Typography className="headerText" >{configJSON.employeeInformation}({this.state.peopleManagementData.length})</Typography>
                    <Box className="searchContainer">
                        <Input
                            className="searchInputfield"
                            fullWidth
                            placeholder="Search"
                            disableUnderline
                            name="cardNumber"
                            data-test-id="cardNumberTestId"
                            onChange={this.handleChange}
                            startAdornment={
                                <SearchIcon className="searchIcon" />
                            }
                        /> 
                        <Button data-testId = "inviteButtonTest" onClick={this.navigateInviteScreen} className="searchBtn" variant="contained">{configJSON.createEmployee}</Button>
                    </Box>
                </PageTop>
                <StyledTableContainer>
                    <StyledTable>
                        <TableHead>
                            <TableRow>
                                <TableCell className="emailTableCell">{configJSON.emailId}</TableCell>
                                <TableCell className="dobTableCell">{configJSON.contactName}</TableCell>
                                <TableCell className="contactNameTableCell">{configJSON.phoneNumber}</TableCell>
                                <TableCell className="phoneNumTableCell">{configJSON.action}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {this.state.peopleManagementData.length > 0 && this.state.peopleManagementData.map((singleData) => {
                                    return (
                                        <TableRow data-testId={singleData.id} key={singleData.id}>
                                            <TableCell className="dobTableTxt">{singleData.attributes.email}</TableCell>
                                            <TableCell className="nationalityText">{singleData.attributes.employee_name}</TableCell>
                                            <TableCell className="countryTableText">{singleData.attributes.full_phone_number}</TableCell>
                                            <TableCell className="countryTableText">
                                                <IconButton
                                                    data-testId = "IconButton_test"
                                                    aria-label="more"
                                                    aria-controls="long-menu"
                                                    aria-haspopup="true"
                                                    onClick={(event) => this.handleClickID(event, singleData.id)}
                                                >
                                                    <MoreVertIcon />
                                                </IconButton>
                                                <MenuStyle
                                                    id="long-menu"
                                                    anchorEl={this.state.anchorEl}
                                                    keepMounted
                                                    open={Boolean(this.state.anchorEl && this.state.selectedId === singleData.id)}
                                                    onClose={this.handleClose}
                                                >
                                                    <MenuItem style={{color:"white",background:"#505050",fontFamily: "Lato",fontSize: "14px",fontWeight: 400,
                                                        lineHeight: "22px",
                                                        textAlign: "left"}} data-testId = "editScreenTet_id" onClick={() => this.goToEditEmployee(singleData.id,"EmployeeEditScreen")}  
                                                        > <img style={{padding:"0px 16px 0px 8px"}} src={require("../assets/edit_.png")} />Edit</MenuItem>
                                                    <MenuItem style={{color:"white",background:"#505050",fontFamily: "Lato",fontSize: "14px",fontWeight: 400,
                                                        lineHeight: "22px",
                                                        textAlign: "left"}} data-testId = "viewkycchecklist_test" onClick={() => this.goToEditEmployee(singleData.id,"ViewKycChicklist")} ><img style={{padding:"0px 16px 0px 8px"}} src={require("../assets/view_.png")} />View kyc checklist</MenuItem>
                                                    <MenuItem style={{color:"white",background:"#505050",fontFamily: "Lato",fontSize: "14px",fontWeight: 400,
                                                        lineHeight: "22px",
                                                        textAlign: "left"}} data-testId = "deleteButton_test" onClick={() => this.handleDialogBox(singleData.id)}><img style={{padding:"0px 16px 0px 8px"}} src={require("../assets/delete.png")} />Delete</MenuItem>
                                                </MenuStyle>
                                            </TableCell>
                                        </TableRow>
                                    )
                                })
                            }
                        </TableBody>
                    </StyledTable>
                </StyledTableContainer>
                <Box className="loadMoreBtnBox">
                    <Button className="loadMoreBtn">{configJSON.loadText}</Button>
                </Box>
            </MainContent>
            <ConfirmDialog
                data-testId="confirmDialog_test"
                open={this.state.isDialogOpen}
                title={configJSON.popUpData}
                onCancel={this.handleCancel}
                onConfirm={this.handleConfirm}
                btnText="Delete"
            />
            <SuccessDialog
                disc={configJSON.sucessMsg}
                open={this.state.successDialogOpen}
                onClose={this.handleCancel}
            />
        </Headers>
      // Customizable Area End
    );
  }
}
// Customizable Area Start
const StyledTableContainer = styled(TableContainer)({
    width: "Fixed (1,264px)px",
    height: "75vh",
    gap: "24px",
    opacity: "0px",
  '&::-webkit-scrollbar': {
    width: '1px', 
  },
  '&::-webkit-scrollbar-track': {
    boxShadow: "inset 0 0 5px grey",
    borderRadius: "10px",
    background:"rgb(24,25,27)"
  },
  '&::-webkit-scrollbar-thumb': {
    background:"rgb(0,255,0)",
    borderRadius: "10px"
  },
  '&::-webkit-scrollbar-thumb:hover': {
    backgroundColor: 'rgb(41,253,50);',
  },

  '&': {
    scrollbarWidth: 'thin',
    scrollbarColor: 'rgb(0,255,0) rgb(24,25,27)', // thumb color and track color
  },
    "& .userImg": {
        width: "48px",
        height: "48px",
        borderRadius: "48px"
    },
    "& .peopleIcon":{
        fontFamily: "Lato",
        fontSize: "14px",
        fontWeight: 400,
        lineHeight: "22px",
        textAlign: "left"
    }
});

const StyledTable = styled(Table)({
    backgroundColor: "#18191b",
    "& .MuiMenu-list":{
        background: "red"
      },
    "& .MuiTableCell-root": {
        verticalAlign: "top",
        borderBottom: "1px solid #272B30",
    },
    "& .MuiIconButton-root":{
        color :"#A7A7A7"
    },
    "& .MuiTableCell-head": {
        color: "#fff",
        width: "max-content",
    },
    "& .MuiTableCell-body": {
        color: "#6F767E",
        fontSize: "14px",
        fontWeight: 600
    },
    "& .dobTableTxt": {
        fontWeight: 600,
        color: "#6F767E",
        fontSize: "14px",
        fontFamily: 'Lato',
        height: "81px",
        width: "24px"
    },
    "& .countryTableText": {
        color: "#6F767E",
        fontSize: "14px",
        fontWeight: 600,
        // width: "84px",
        height: "24px",
        fontFamily: 'Lato',
    },
    "& .nationalityText": {
        width: "80px",
        color: "#6F767E",
        fontSize: "14px",
        fontWeight: 600,
        fontFamily: 'Lato',
        height: "24px"
    },
    "& .lato": {
        fontFamily: 'Lato'
    },
    "& .dobTableCell": {
        width: "30%",
        fontFamily: 'Lato',
        color: 'rgba(211, 211, 211, 1)',
        height: '16px',
        gap: '0px',
        opacity: "0px",
        fontWeight: 600
    },
    "& .emailTableCell":{
        width: "40%",
        fontFamily: 'Lato',
        color: 'rgba(211, 211, 211, 1)',
        height: '16px',
        gap: '0px',
        opacity: "0px",
        fontWeight: 600
    },
    "& .contactNameTableCell":{
        color: 'rgba(211, 211, 211, 1)',
        width: "20%",
        fontFamily: 'Lato',
        height: '16px',
        gap: '0px',
        opacity: "0px",
        fontWeight: 600
    },
    "& .phoneNumTableCell":{
        fontFamily: 'Lato',
        color: 'rgba(211, 211, 211, 1)',
        height: '16px',
        width: "10%",
        gap: '0px',
        opacity: "0px",
        fontWeight: 600
    },
    "& .peopleIcon":{
        fontFamily: "Lato",
        fontSize: "14px",
        fontWeight: 400,
        lineHeight: "22px",
        textAlign: "left"
    }
})

const PageTop = styled(Box)({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: "44px",
    paddingBottom: "20px",
    "& .searchContainer": {
        display: "flex",
        justifyContent: "flex-start",
        gap: "15px",
        outline: "none",
        alignItems: "center",
        border: "none",
        borderRadius: "0px"
    },
    "& .searchInputfield": {
        backgroundColor: "#505050",
        color: "#fff",
        height: "44px",
        fontSize: "16px",
        fontWeight: 400,
        fontFamily: "Lato",
        width: "320px",
        outline: "none",
        border: "none",
        borderRadius: "0",
        padding: "10px",
        "& .MuiInputBase-input": {
            width: "272px",
            height: "24px",
            fontFamily: "Lato",
        }
    },
    "& .MuiOutlinedInput-inputAdornedStart": {
        color: "#A7A7A7",
        height: "7px",
    },
    "& .searchIcon": {
        color: "#A7A7A7",
        marginRight: "20px",
        width: "17.05px",
        height: "17.06px"
    },
    "& .searchBtn": {
        height: "44px",
        width: "181px",
        backgroundColor: "#00FF00",
        color: "#242424",
        borderRadius: "0px",
        textTransform: "none",
    },
    "& .pageMain": {
        height: "44px",
        width: "1333px",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center"
    },

})

const MainContent = styled(Box)({
    padding: "20px",
    height:"95vh",
    backgroundColor: "rgba(26, 29, 31, 1)",
    fontFamily: 'Lato',
    "& .MuiPopover-paper":{
        background:"red"
    },
    "& .headerText": {
        display: "flex",
        color: "rgba(252, 252, 252, 1)",
        fontSize: "40px",
        fontWeight: 600,
        fontFamily: 'Lato',
        width: "510px",
        height: "32px",
        marginBottom: "20px"
    },
    "& .loadMoreBtnBox": {
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginTop: "16px",
        height:"80px",
        color:"#6F767E"
    },
    "& .loadMoreBtn": {
        textTransform: "none",
        color: "white",
        height: "48px",
        border: "2px solid rgba(39, 43, 48, 1)",
        fontSize: "15px",
        fontWeight: 700,
        borderRadius: "12px",
        fontFamily: 'Lato',
    },
})

const MenuStyle = styled(Menu)({
    "& .MuiPopover-paper":{
        background: "#505050"
      },
})
// Customizable Area End
