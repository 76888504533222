import React from 'react';

// Customizable Area Start
import {
  Container,
  Box,
  Button,
//   IconButton,
  Grid,
  Typography,
  TextField,
} from '@mui/material';
import {
  createTheme,
} from '@mui/material/styles';
import {  ArrowBack } from '@mui/icons-material';
import { IconButton,  Input,InputAdornment, FormControlLabel, } from '@material-ui/core';
import { checked, uncheck } from "./assets";
import {  Visibility, VisibilityOff } from "@material-ui/icons";
import { styled } from '@material-ui/styles';
import Headers from '../../../components/src/Headers.web';
import EmployeHeader from '../../../components/src/EmployeHeader.web';
import UserProfileBasicController, {
  Props,
} from './UserProfileBasicController.web';

const theme = createTheme({
  palette: {
    primary: {
      main: '#0000ff',
      contrastText: '#fff',
    },
  },
});
// Customizable Area End

export default class ChangePassword extends UserProfileBasicController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderChangePassword = ()=>{
    return (
      <>
        <StyledContainer maxWidth="sm">
          <BoxContainer>
            <Grid className='headerBox' container spacing={2} alignItems="center">
              <Grid style={{ padding: "0px" }} item>
                <IconButton edge="start">
                  <ArrowBack data-testId="backButtonTest" onClick={() => this.goToScreen("UserProfileBasicWeb")} style={{width: "40px",height: "40px", color: "white"}}/>
                </IconButton>
              </Grid>
              <Grid style={{ padding: "0px 16px" }} item>
                <Typography className='headerTxt' variant="h5">Change Password</Typography>
              </Grid>
            </Grid>
            <Grid container rowSpacing={1} columnSpacing={{xs:1, sm:2,md:3}} style={{display: "flex",flexDirection: "column",paddingLeft: "4%"}}>
            <form noValidate autoComplete="off">
              <Grid item xs={6} style={{maxWidth: "70%"}}> 
                <Box className="inputContainer" data-testId="render2">
                  <label className="labelText">Old Password <span style={{ color: 'red' }}>*</span></label>
                  <Input
                      placeholder="Old Password"
                      className="inputFieldStyle"
                      type={this.state.enableOldPasswordField? "password" : "text"}
                      data-testId="oldPasswordTest"
                      name="oldPassword"
                      inputProps={{ disableUnderline: true,maxLength: 32 }}
                      autoComplete="off"
                      value = {this.state.oldPassword}
                      onChange={this.handleChange}
                      style={ { border: "1px solid #FFFFFF" } }
                      endAdornment={
                      <InputAdornment position="end">
                          <IconButton
                          style={{color: "white"}}
                          edge="end"
                          onClick={this.handleOldPasswordShow}
                          aria-label="toggle password visibility"
                          data-testId="oldPasswordToggleTestId"
                          >
                          {this.state.enableOldPasswordField? (
                              <VisibilityOff />
                          ) : (
                              <Visibility />
                          )}
                          </IconButton>
                      </InputAdornment>
                    }
                  />
                  
                  </Box>
              </Grid>
              <Grid item xs={6} style={{maxWidth: "70%"}}>              
              <Box className="inputContainer" data-testId="render2">
                    <label className="labelText">New Password <span style={{ color: 'red' }}>*</span></label>
                  <Input
                      placeholder="New Password"
                      className="inputFieldStyle"
                      type={this.state.enablePasswordField ? "password" : "text"}
                      data-testId="newPasswordTest"
                      name = "newPassword"
                      value = {this.state.newPassword}
                      onChange={this.handleChange.bind(this)}
                      inputProps={{ disableUnderline: true,maxLength: 32 }}
                      autoComplete="off"
                      style={ { border: "1px solid #FFFFFF" } }
                      endAdornment={
                      <InputAdornment position="end">
                          <IconButton
                          style={{color: "white"}}
                          edge="end"
                          onClick={this.handlePasswordShow}
                          aria-label="toggle password visibility"
                          >
                          {this.state.enablePasswordField ? (
                              <VisibilityOff />
                          ) : (
                              <Visibility />
                          )}
                          </IconButton>
                      </InputAdornment>
                      }
                    /> 
                    {this.state.validPass ? null : (
                        <Typography className="errorMsg">
                          Kindly meet all the provided conditions
                        </Typography>
                      )}        
              </Box>
              </Grid>
              <Grid item xs={6} style={{maxWidth: "70%"}}>
              <Box className="inputContainer" data-testId="render2">
              <label className="labelText">Confirm New Password <span style={{ color: 'red' }}>*</span></label>
                  <Input
                      placeholder="Confirm Password"
                      className="inputFieldStyle"
                      type={this.state.enableConfirmPasswordField ? "password" : "text"}
                      data-testId="confirmPasswordTest"
                      name="confirmPassword"
                      value = {this.state.confirmPassword}
                      onChange={this.handleChange}
                      inputProps={{ disableUnderline: true,maxLength: 32 }}
                      autoComplete="off"
                      style={ { border: "1px solid #FFFFFF" } }
                      endAdornment={
                      <InputAdornment position="end">
                          <IconButton
                          style={{color: "white"}}
                          edge="end"
                          onClick={this.handleConfirmPasswordShow}
                          aria-label="toggle password visibility"
                          >
                          {this.state.enableConfirmPasswordField ? (
                              <VisibilityOff />
                          ) : (
                              <Visibility />
                          )}
                          </IconButton>
                      </InputAdornment>
                      }
                  />  
                  {!this.state.confirmPass ? (
                    <Typography className="errorMsg">Password must be same </Typography>
                  ) : null}     
              </Box>
              
              </Grid>
              <Grid item xs={6} style={{maxWidth: "70%",marginTop:"1rem"}}>
              <Box><LebelStyle variant="h6"  className="passCondLable">Your password must contain</LebelStyle></Box>
              <Box className="controlBox">
                <FormControlLabel
                  disabled
                  className="checkContainer"
                  control={
                    <Box className="checkImg">
                      {this.state.employeErrorData.newPassword.alphabet ? (
                        <img src={checked} />
                      ) : (
                        <img src={uncheck} />
                      )}
                    </Box>
                  }
                  label={
                    <LebelStyle className='checkBoxlabel' variant="h6" style={{ color: "white" }}>
                      At least one alphabet
                    </LebelStyle>
                  }
                />
                <FormControlLabel
                  disabled
                  className="checkContainer"
                  control={
                    <Box className="checkImg">
                      {this.state.employeErrorData.newPassword.number ? (
                        <img src={checked} />
                      ) : (
                        <img src={uncheck} />
                      )}
                    </Box>
                  }
                  label={
                    <LebelStyle className='checkBoxlabel' variant="h6" style={{ color: "white" }}>
                      At least one number
                    </LebelStyle>
                  }
                />
                <FormControlLabel
                  disabled
                  className="checkContainer"
                  control={
                    <Box className="checkImg">
                      {this.state.employeErrorData.newPassword.specialSign ? (
                        <img src={checked} />
                      ) : (
                        <img src={uncheck} />
                      )}
                    </Box>
                  }
                  label={
                    <LebelStyle className='checkBoxlabel' variant="h6" style={{ color: "white" }}>
                      At least one special character
                    </LebelStyle>
                  }
                />
                <FormControlLabel
                  disabled
                  control={
                    <Box className="checkImg">
                      {this.state.employeErrorData.newPassword.capitalChar ? (
                        <img src={checked} />
                      ) : (
                        <img src={uncheck} />
                      )}
                    </Box>
                  }
                  className="checkContainer"
                  label={
                    <LebelStyle className='checkBoxlabel' variant="h6" style={{ color: "white" }}>
                      At least one character in caps
                    </LebelStyle>
                  }
                />
                <FormControlLabel
                  disabled
                  className="checkContainer"
                  control={
                    <Box className="checkImg">
                      {this.state.employeErrorData.newPassword.minimumChar ? (
                        <img src={checked} />
                      ) : (
                        <img src={uncheck} />
                      )}
                    </Box>
                  }
                  label={
                    <LebelStyle className='checkBoxlabel' variant="h6" style={{ color: "white" }}>
                      Minimum character length is 8 characters
                    </LebelStyle>
                  }
                />
              </Box>
              </Grid>  
              <Grid style={{marginTop:"1.5rem"}} container spacing={2}>
                <Grid item>
                  <Button className='btnIEmp colorBtn' variant="contained"
                    style={
                    this.state.enableBtn
                      ? { backgroundColor: "#7C7C7C" }
                      : { backgroundColor: "#00FF00",outline:"none" }
                  }
                  disabled={this.state.enableBtn}
                  onClick={this.passwordCheck}
                  data-testId="changePassTest"
                  >
                    <Typography className='btnTypo btntest'>Change Password</Typography>
                  </Button>
                </Grid>
                <Grid item>
                  <Button onClick={() => this.goToScreen("UserProfileBasicWeb")} data-testId="formDataTestId" className='btnIEmp' variant="contained">
                    <Typography className='btnTypo'>Cancel</Typography>
                  </Button>
                </Grid>
            </Grid>
            </form>
            </Grid>
            
          </BoxContainer>
        </StyledContainer>
      </>
    )

  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <>
      {
        this.state.role === "company" ? 
        <Headers sliderButton={this.state.sideBarClose}
          onToggleClick={this.handleSliderButton}
          logOutToogle={this.logOutBtn} 
          data-testId="logoutTestId"
          goToScreen = {this.goToScreen}
          isRouteData={"Contactus"}
        >
          {this.renderChangePassword()}         
        </Headers> 
      :
      <EmployeHeader sliderButton={this.state.sideBarClose}
          onToggleClick={this.handleSliderButton}
          logOutToogle={this.logOutBtn} 
          data-testId="logoutTestId"
          goToScreen = {this.goToScreen}
          isRouteData={"Contactus"}
        >  
          {this.renderChangePassword()}
      </EmployeHeader>
      }
      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const StyledContainer = styled(Container)({
  height:"100vh",
  maxWidth:"100vw !important",
  backgroundColor: "#242424",
  paddingTop: "24px",
  overflowY:"scroll",
  "& .inputContainer": {
    display: "flex",
    flexDirection: "column",
    marginTop: "3%",
  },
  "& .inputFieldStyle": {
    width: "100%",
    height: "47.6px",
    backgroundColor: "#242424",
    color: "#A7A7A7",
    border: "1px solid #FFFFFF",
    padding: "16px",
    fontFamily: "Lato",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "24px",
    textAlign: "left",
  },
  "& .inputFieldStyle.error": {
    border: "1px solid red",
  },
  "& .inputFieldStyle:focus": {
    outline: "none",
  },
  "& .errorMsg": {
    color: "red",
    fontSize: "12px",
  },
})
const BoxContainer = styled(Box)({
  height:"496px",
  top:"120px",
  "& .labelText":{
    fontFamily: "Lato",
    fontSize: "14px",
    fontWeight: 600,
    lineHeight: "24px",
    letterSpacing: "-0.01em",
    textAlign: "left",
    color:"#EFEFEF",
    paddingBottom:"12px"
  },
  "& .headerBox":{
    height:"48px",
    margin:"0px 0px 32px 0px",
  },
  "& .headerTxt":{
    fontFamily:"Lato",
    fontSize: "40px",
    fontWeight: "600",
    lineHeight: '48px',
    letterSpacing: "-0.02em",
    textAlign: "left",
    color:"#FCFCFC"
  },
  "& .btnIEmp":{
    width:"176px",
    height:"48px",
    padding:"12px 20px",
    background:"transparent",
    border:"1px solid",
    borderRadius:"0px",
    textTransform: "capitalize",
    transition: "background 0.3s ease" ,
  },
  "& .btnIEmp:hover": {
    background: "#2f2f2f"
  }, 
  "& .colorBtn":{
    background: "#7C7C7C"
  },
 "& .btnTypo":{
    fontSize: "16px",
    fontWeight: 600,
    color: "#00FF00",
    fontFamily: "Lato",
    lineHeight: "16px",
    textAlign: "center",
  },
  "& .btntest":{
    color:"black"
  },
  "& .passCondLable":{
    color: "#D3D3D3",
    fontFamily: 'Lato',
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '24px',
    textAlign: 'left',
    // marginTop: '16px',
  },
  "& .controlBox": {
    display: "flex",
    width: "100%",
    flexDirection: "column",
    justifyContent: "space-evenly",
    marginTop: "1%",
    marginLeft: "2%",
  },
  "& .checkBoxlabel": {
    color: "#D3D3D3",
    fontFamily: "Lato",
    fontSize: "12px",
    fontWeight: 400,
    lineHeight: "18px",
    textAlign: "left",
  },
  "& .checkContainer": {
    marginTop: "2%",
  },
  "& .checkImg": {
    paddingRight: "2%",
  },
})

const LebelStyle = styled(Typography)({
  fontSize: "12px",
  lineHeight: "unset",

  "@media only screen and (max-width: 1024px)": {
    fontSize: "small",
  },
});
// Customizable Area End
